import { FuseLoadable } from '@fuse';

export const cfiConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: '/cfi',
            component: FuseLoadable({
                loader: () => import('./cfi')
            }),
        }
    ]
};
