import React from 'react';
import { Redirect } from 'react-router-dom';
import { FuseUtils } from '@fuse/index';
import { SignUpPageConfig } from 'main/content/auth/SignUp/SignUpPageConfig';
import { Login2PageConfig } from 'main/content/auth/Login/Login2PageConfig';
import { layoutConfig } from 'main/content/layout/LayoutConfig';
import { financialConfig } from 'main/content/financial/FinancialConfig';
import { profileConfig } from 'main/content/profile/ProfileConfig';
import { currentIPOConfig } from 'main/content/currentIPO/CurrentIPOConfig'
import { VerfiyEmailConfig } from 'main/content/auth/VerifyEmail/VerfiyEmailConfig'
import { ForgotPasswordConfig } from 'main/content/auth/ForgotPassword/ForgotPasswordConfig'
import { LanguagesConfig } from 'main/content/utils/LanguagesConfig'
import {cfiConfig} from "../main/content/cfi/cfiConfig";

const routeConfigs = [
  layoutConfig,
  financialConfig,
  profileConfig,
  SignUpPageConfig,
  Login2PageConfig,
  currentIPOConfig,
  VerfiyEmailConfig,
  ForgotPasswordConfig,
  LanguagesConfig,
  cfiConfig
];

export const routes = [
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs),
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/login" />
  },
];
